// work data

export const Work = [
    {
        id:1,
        name:"Legal Gen",
        description:"AI powered legal document generator. It is a fullstack web application build using ReactJS, NodeJS, ExpressJS and MongoDB.",
        tags:["react","mongoDB","styledComponents"],
        
        demo:"https://bibhashanand.com",
        github:"https://github.com/bibhashanand/"
    },
    {
        id:2,
        name:"HallBook",
        description:"Auditorioum booking website for university students. It is a fullstack web application build using HTML, CSS, Handelbars and MySQL.",
        tags:["HTML","CSS","MySQL"],
        
        demo:"https://bibhashanand.com",
        github:"https://github.com/bibhashanand/"
        
    },
    {
        id:3,
        name:"Various mini projects",
        description:"Various mini projects build using HTML, CSS, JS and by making use of some external APIs.",
        tags:["HTML","CSS","JS","APIs"],
        
        demo:"https://projects.bibhashanand.com",
        github:"https://github.com/bibhashanand/"
    },
    // {
    //     id:4,
    //     name:"Sidebar Menu",
    //     description:"A cool looking sidebar menu build using ReactJS and styledComponents.It has really smooth animations.",
    //     tags:["react","styledComponents","react-router"],
        
    //     demo:"https://react-sidebar.vercel.app/",
    //     github:"https://github.com/codebucks27/react-sidebar"
       
    // },{
    //     id:5,
    //     name:"Todo App(React + Redux)",
    //     description:"A todo app build using Redux-toolkit and framer-motion for smooth animations.",
    //     tags:["react","redux","framer-motion"],
        
    //     demo:"http://react-redux-todo-app-git-main-codebucks27.vercel.app/",
    //     github:"https://github.com/codebucks27/React-Redux-Todo-App"
        
    // },
    // {
    //     id:6,
    //     name:"Responsive Navigation Menu",
    //     description:"A reponsive navbar menu component in ReactJS with react-router functionalities. Built from scratch.",
    //     tags:["react","react-router","css"],
        
    //     demo:"http://react-responsive-navbar-codebucks27.vercel.app/",
    //     github:"https://github.com/codebucks27/React-responsive-navbar"
        
    // },
    
  
] 